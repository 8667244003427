<template>
  <div
    class="w-full bg-white rounded shadow p-1 sm:p-2 flex justify-between items-center gap-2"
  >
    <div class="flex items-center gap-2">
      <div class="flex flex-col items-center justify-center gap-1">
        <Image
          class="w-11 sm:w-14"
          size="s"
          :images="item.imagen"
          :alt="item.nombre"
        />
        <p class="text-xs bg-gray-100 rounded p-1 sm:px-2 sm:text-sm">
          {{ item.unidad }}
        </p>
      </div>
      <div class="flex flex-col justify-between min-h-16 sm:min-h-20">
        <p class="text-xs sm:text-sm min-h-4 text-gray-600">{{ item.marca }}</p>
        <p class="text-sm sm:text-base font-medium leading-4">
          {{ item.nombre }}
        </p>
        <div class="flex min-h-4 sm:min-h-5">
          <picture v-for="sello in item.sellos" :key="sello.id">
            <source :srcset="sello.icon_full_path + '.png'" type="image/png" />
            <source
              :srcset="sello.icon_full_path + '.webp'"
              type="image/webp"
            />
            <img
              class="mr-2 w-4 sm:w-5"
              :src="sello.icon_full_path + '.png'"
              :alt="sello.nombre"
              width="16px"
              height="16px"
            />
          </picture>
        </div>
      </div>
    </div>
    <div>
      <p class="sm:text-lg">
        {{ moneda(item.precio) }}
      </p>
      <p
        class="text-xs sm:text-sm text-center text-gray-400 line-through"
        v-if="item.precio < item.precio_original"
      >
        {{ moneda(item.precio_original) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { Item } from "@lib/interfaces";
  import Image from "./Image.vue";
  import { moneda } from "@lib/formatters";

  interface Props {
    item: Item;
  }
  defineProps<Props>();
</script>
