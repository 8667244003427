<template>
  <TransitionRoot
    appear
    :show="$productoSoloActivoCd != undefined"
    as="template"
  >
    <Dialog as="div" class="relative z-30 w-full">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full justify-center text-center items-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="transform bg-white text-left align-middle shadow-xl transition-all rounded-xl mx-6"
            >
              <button
                class="absolute right-0 top-0 p-2"
                @click="setearProductoSoloActivoCd(undefined)"
              >
                <svg
                  class="h-6 w-6 text-gray-400 md:hover:text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <!-- CONTENIDO -->
              <div
                v-if="$productoSoloActivoCd && primeraVentanaDisponibleCd"
                class="px-4 py-5"
              >
                <!-- CASO ITEMS INCOMPATIBLES CON CD -->
                <div
                  class="flex flex-col justify-center items-center max-w-xl lg:max-w-5xl sm:px-14 sm:py-4 lg:py-8 lg:px-20"
                  v-if="itemsInactivosCd.length"
                >
                  <h2
                    class="text-sm leading-5 font-bold text-center mb-1.5 sm:text-2xl sm:leading-8 lg:text-3xl lg:max-w-2xl"
                  >
                    No podemos despachar el/los producto(s) en el horario
                    seleccionado.
                  </h2>
                  <p
                    class="text-xs leading-none text-center text-gray-700 mb-5 sm:text-lg sm:leading-7 sm:font-semibold lg:max-w-2xl"
                  >
                    ¿Deseas cambiar el horario para
                    <span class="text-green-500">
                      {{
                        fechaRangoHoraShort(
                          primeraVentanaDisponibleCd?.fecha,
                          primeraVentanaDisponibleCd?.inicio,
                          primeraVentanaDisponibleCd?.termino,
                        ).toLocaleLowerCase()
                      }}
                      y eliminar los productos
                    </span>
                    o seguir con la compra?
                  </p>
                  <div
                    class="bg-gray-100 rounded-lg pr-2 mb-3 sm:max-w-96 lg:max-w-3xl"
                    :class="{
                      'pr-3 scrollbar': itemsInactivosCd.length > 3,
                      'w-full': itemsInactivosCd.length > 1,
                    }"
                  >
                    <div
                      class="p-3 grid grid-cols-1 lg:grid-cols-2 gap-6 overflow-x-auto max-h-52 sm:max-h-64 scrollbar lg:max-h-32"
                      :class="{
                        'lg:grid-cols-2': itemsInactivosCd.length > 1,
                        'lg:grid-cols-1': itemsInactivosCd.length == 1,
                      }"
                    >
                      <CardItem
                        v-for="item in itemsInactivosCd"
                        :item="item"
                        :key="item.producto_id"
                      >
                      </CardItem>
                    </div>
                  </div>
                  <Button
                    class="w-full text-xs md:text-base mb-2 lg:max-w-2xl sm:max-w-96"
                    type="secondary"
                    @click="aplicarCambios"
                    >Eliminar productos y cambiar despacho</Button
                  >
                  <Button
                    class="w-full text-xs md:text-base sm:max-w-96 lg:max-w-2xl"
                    @click="setearProductoSoloActivoCd(undefined)"
                    >Continuar sin cambios</Button
                  >
                </div>
                <!-- CASO SIN ITEMS INCONPATIBLES CON CD -->
                <div
                  class="flex flex-col justify-center items-center md:text-4xl md:leading-10 max-w-96"
                  v-else
                >
                  <div class="w-24 shadow-md mb-1.5 rounded-lg overflow-hidden">
                    <Image
                      :alt="$productoSoloActivoCd.nombre"
                      :images="$productoSoloActivoCd.imagesUrls"
                      size="s"
                    />
                  </div>
                  <h2 class="text-2xl leading-7 font-bold mb-1 md:text-4xl">
                    ¡Atención!
                  </h2>
                  <p
                    class="text-sm leading-5 font-normal text-center text-gray-700 mx-5 mb-2 md:text-lg md:leading-7 md:mb-3"
                  >
                    Al agregar este producto, haremos un cambio en el horario de
                    despacho para
                    <span class="text-green-500">
                      {{
                        fechaRangoHoraShort(
                          primeraVentanaDisponibleCd?.fecha,
                          primeraVentanaDisponibleCd?.inicio,
                          primeraVentanaDisponibleCd?.termino,
                        ).toLocaleLowerCase()
                      }}.
                    </span>
                  </p>
                  <div class="flex w-full gap-2">
                    <Button
                      class="w-1/2 text-xs md:text-base"
                      @click="setearProductoSoloActivoCd(undefined)"
                      >Cancelar</Button
                    >
                    <Button
                      class="w-1/2 text-xs md:text-base"
                      type="secondary"
                      @click="aplicarCambios"
                      >Aceptar cambio</Button
                    >
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import { computed } from "@vue/runtime-core";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
  } from "@headlessui/vue";
  import { useStore } from "@nanostores/vue";
  import {
    productoSoloActivoCd,
    setearProductoSoloActivoCd,
  } from "@stores/app";
  import {
    zonaDespachoCd,
    direccionActiva,
    setearDespacho,
    setearRestringirRetiroTienda,
  } from "@stores/despacho";
  import {
    itemsCarro,
    limpiarItemsNoActivosCd,
    addToCart,
  } from "@stores/carro";
  import Button from "@components/ui/Button.vue";
  import Image from "./Image.vue";
  import { fechaRangoHoraShort } from "@lib/formatters";
  import CardItem from "./CardItem.vue";

  const $productoSoloActivoCd = useStore(productoSoloActivoCd);
  const $itemsCarro = useStore(itemsCarro);
  const $zonaDespachoCd = useStore(zonaDespachoCd);
  const $direccionActiva = useStore(direccionActiva);

  const itemsInactivosCd = computed(() => {
    return $itemsCarro.value.filter((item) => !item.activo_cd);
  });

  const primeraVentanaDisponibleCd = computed(() => {
    if (!$zonaDespachoCd.value?.proximas_ventanas) return;
    for (const ventana of $zonaDespachoCd.value?.proximas_ventanas) {
      if (ventana.estado && ventana.limite > ventana.cantidad_pedidos) {
        return ventana;
      }
    }
  });

  const aplicarCambios = () => {
    if (!primeraVentanaDisponibleCd.value) return;
    if (!$direccionActiva.value) return;
    if (!$productoSoloActivoCd.value) return;
    limpiarItemsNoActivosCd();
    setearDespacho(primeraVentanaDisponibleCd.value, 1, $direccionActiva.value);
    addToCart($productoSoloActivoCd.value);
    setearProductoSoloActivoCd(undefined);
    setearRestringirRetiroTienda(true);
  };
</script>
